<template>
<div class="page">
    <NavBottom />

	<div class="main">
		<img class="tophead" src="../assets/std_top.jpg" mode="widthFix"/>
		<div class="midwrp">
			<img class="tophead" src="../assets/std_mid.jpg" mode="widthFix"/>
			<div class="content">
				<img class="titimg" src="../assets/std_tit0.jpg" mode="widthFix"/>
				<div class="textcont">
					<div>
						登入<a href="http://www.e-chinalife.com">www.e-chinalife.com</a> 查询或下载电子保单；</div>
					<div>操作路径：客户服务-团体客户服务-电子保单查询下载-个人查询入口。</div>
					<div>PS：保单号，已短信的发送发送到投保人的手机号；请自行查询。如未收到请致电95519。</div>
				</div>
			</div>
		</div>
		<div>
			<img class="titimg" src="../assets/std_tit1.jpg" mode="widthFix" />
			<div class="textcont">
				<div>1、请携带投保人身份证原件、被保险人户口本；前往中国人寿公司窗口开具。</div>
				<div>2、中国人寿温州市各分支机构地址：</div>
				<div>①中国人寿温州分公司</div>
				<div>温州市鹿城区会展路中国人寿大楼3楼。（温州市会展中心东首）</div>
				<div>②中国人寿温州瓯海支公司5-6楼</div>
				<div>温州市瓯海区雪山路205号瓯海大厦</div>
				<div>③中国人寿温州龙湾支公司（含浙南经济技术开发区）</div>
				<div>温州市龙湾区京华路万鑫锦园1-6幢202-205室</div>
				<div>④中国人寿温州洞头支公司</div>
				<div>温州市洞头区北岙邮电路96、98、100号</div>
				<div>⑤中国人寿温州乐清支公司</div>
				<div>乐清市伯乐路287号中国人寿大楼（南华寄宿小学南面）</div>
				<div>⑥中国人寿温州瑞安支公司</div>
				<div>瑞安市安阳万松东路178号安阳大厦2层</div>
				<div>⑦中国人寿温州永嘉支公司</div>
				<div>温州市永嘉县上塘县前路中国人寿大楼（下堡东路与县前路交叉口）</div>
				<div>⑧中国人寿温州平阳支公司</div>
				<div>温州市平阳县昆阳平甫路嘉华锦苑4幢4楼</div>
				<div>⑨中国人寿温州苍南支公司（含龙港市）</div>
				<div>温州市苍南县灵溪江湾北路嘉恒家居广场2号楼5-6层</div>
				<div>⑩中国人寿温州文成支公司</div>
				<div>温州市文成县大峃伯温路移动通信大楼6-7楼</div>
				<div> 中国人寿温州泰顺支公司</div>
				<div>温州市泰顺县罗阳新城大道华鸿中心广场29幢2楼</div>
				<div style="text-align: center;">（以上信息来源：企查查）</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		methods: {

		}
	}
</script>

<style>
	.page {
		font-size: 0;
		background-image: url(../assets/std_rept.jpg);
		background-repeat: repeat-y;
		background-size: 100% auto;
		background-position: center top;
		padding-bottom: 70px;
	}

	.midwrp {
		position: relative;
	}

	.tophead {
		width: 100%;
	}

	.titimg {
		width: 80%;
		margin: 5px 10%;
	}

	.textcont {
		margin: 10px;
		padding: 10px;
		font-size: 15px;
		box-shadow: 3px 6px 6px 0 rgba(0, 0, 0, .2);
		background-color: #4ab5e1;
		color: #fff;
		line-height: 1.8;
	}

	.content {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}
</style>
